




import Vue from 'vue';
import { vuetifyComponents } from '@/plugins/vuetify';
import { mapState } from 'vuex';

export default Vue.extend({
  name: 'todo',
  components: {
    ...vuetifyComponents,
  },
  computed: {
    ...mapState({
      user: (state: any) => state.user.user,
    }),
  },
  props: {
    due: String,
    note: String,
    label: String,
  },
});
